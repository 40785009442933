.hero-wrapper{
    color:white;
    position: relative;
    padding-bottom: 2rem;
   background-color: black;
   z-index: 1;
}
.hero-container{
    justify-content: space-between;
    align-items: flex-end;
}
.image-container{
    width:30rem;
    height:35rem;
    overflow: hidden;
   
}
.image-container>img{
    width: 90%;
    height: 100%;
}
.hero-left{
gap: 3rem;
}
.hero-title{
    position: relative;
    z-index: 1;
}
.hero-title>h1{
font-weight:600;
font-size: 3.8rem;
line-height: 4.5rem;
}
.orange-circle{
    height:4rem;
    width:4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right:18%;
    top:-10%;
    z-index:-1;
}
.button{
    font-weight: 500;
    position: absolute;
   right:76%;
   top:80%;
    color: white;
  background: var(--blue-gradient);
  border:none;
  border-radius: 4px;
  transition: all 300ms ease-in;
  }
  .button:hover{
    cursor:pointer;
    transform: scale(1.1)
  }



.search-bar {
  background-color: white;
  border-radius: 5px;
  border: 3px solid rgba(120, 120, 120, 0.374);
  padding: 0.5rem 1rem;
  width: 100%;
  justify-content: space-between;
}



@media  (max-width: 640px){
  .hero-container{
    margin-top: 2rem;
  }
}

